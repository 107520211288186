import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from "vuelidate"
import { mapState, mapMutations } from "vuex";

import App from './App.vue';

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./themes/jhg.scss";

import api from "./api";
import store from "./store";
import router from "./router";
import { Translate, vuePhoneNumberInputTranslations } from "@/lib/translator"

Vue.prototype.$api = api;

Vue.filter('formatDate', function(value) {
  if (!value) { return '(n/a)'; }
  let d = new Date(value);
  return d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' : '') + (d.getMonth() + 1) + '-' +
    ((d.getDate() < 10) ? '0' : '') + d.getDate();
});
           
Vue.filter('defaultValue', function(value, def) {
  if (!value) { return def; }
  return value;
});

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app');

Vue.mixin({
  computed: {
    ...mapState('language', ['selectedLanguage']),
  },
  methods: {
    T: function(key) {
      return Translate(key, this.selectedLanguage);
    },
    Language: function() {
      return this.selectedLanguage;
    },
    phoneNumberInputTranslations: function() {
      return vuePhoneNumberInputTranslations(this.selectedLanguage);
    },
    ...mapMutations('language', ['setLanguage']),
  }
});

